/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import photo from '../../images/foto-kurs.jpg';
import Spinner from '../layout/Spinner';

const ClassSchedule = () => {
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const DaysEnum = Object.freeze({
    Poniedziałek: 0,
    Wtorek: 1,
    Środa: 2,
    Czwartek: 3,
    Piątek: 4,
    Sobota: 5,
    Niedziela: 6,
  });

  const [fetchData] = useLazyQuery(GET_LESSONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setLessons([...data.lessons.nodes]);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const compareClassesBasedOnDay = (a, b) => {
    return DaysEnum[a.lesson.day] - DaysEnum[b.lesson.day];
  };

  const getTime = (node) => {
    return node.lesson.startTime.split(':').reduce((prev, curr) => {
      return +prev * 60 + +curr;
    });
  };

  const compareClassesBasedOnStartTime = (a, b) => {
    if (DaysEnum[a.lesson.day] === DaysEnum[b.lesson.day]) {
      const firstLessonTime = getTime(a);
      const secondLessonTime = getTime(b);

      return firstLessonTime - secondLessonTime;
    }
    return 0;
  };

  const renderClasses = () => {
    const sortedLessons = lessons.sort(compareClassesBasedOnDay).sort(compareClassesBasedOnStartTime);
    if (sortedLessons.length > 0) {
      return sortedLessons.map((node) => {
        return (
          <tr key={node.id}>
            <td>{node.lesson.groupName}</td>
            <td>{node.lesson.day}</td>
            <td>{node.lesson.startTime}</td>
            <td>{node.lesson.endTime}</td>
          </tr>
        );
      });
    }
    return null;
  };

  return (
    <section className="schedule">
      <div className="row">
        <div className="schedule__text">
          <h3>Kurs</h3>
          <p>
            Tradycyjne, najprawdziwsze tango z przedmieść Buenos Aires i Montevideo.
            <br />
            Bardzo intensywne i ciekawe zajęcia.
          </p>
          <p>
            Kurs obejmuje
            <span> 6 spotkań po 90min. </span>
            Zajęcia raz w tygodniu.
          </p>
          <p>Koszt kursu: 200zł/osoby - przy płatności po pierwszych zajęciach.</p>
          <p>Pojedyncze lekcje (w grupie): 40zł/osoby - przy płatności z lekcji na lekcję.</p>
        </div>
        <div className="schedule__photo">
          <img src={photo} alt="kurs" />
        </div>
      </div>
      <div className="schedule__table">
        <h3>Grafik zajęć</h3>
        {isLoading ? (
          <Spinner />
        ) : (
          <table>
            <thead>
              <tr>
                <th>Grupa</th>
                <th>Dzień</th>
                <th>Początek</th>
                <th>Zakończenie</th>
              </tr>
            </thead>
            <tbody>{!isLoading && renderClasses()}</tbody>
          </table>
        )}
        {!isLoading && lessons.length < 1 && <h2>Brak lekcji do wyświetlenia...</h2>}
      </div>
    </section>
  );
};

export default ClassSchedule;

const GET_LESSONS = gql`
  query getLessons {
    lessons {
      nodes {
        id
        lesson {
          day
          startTime
          endTime
          groupName
        }
      }
    }
  }
`;
